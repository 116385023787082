<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="record">
        <div class="top">
            <ul>
                <li :class="{ on: curIndex === 0 }" @click="TabCont(0)">
                    全部
                    <i></i>
                </li>
                <li :class="{ on: curIndex === 1 }" @click="TabCont(1)">
                    处理中
                    <i></i>
                </li>
                <li :class="{ on: curIndex === 2 }" @click="TabCont(2)">
                    已受理
                    <i></i>
                </li>
            </ul>
            <div class="search">
                <el-input v-model="search" placeholder="输入搜索关键字" @focus="focus" @blur="focus"> </el-input>
                <i :class="getclass" @click="searchInput"></i>
            </div>
        </div>
        <div class="content">
            <div v-if="!tabledata.length" class="empty">
                <img :src="notIcon" alt="" />
                <p>暂无反馈记录</p>
            </div>
            <el-table v-if="tabledata.length" :data="tabledata" :stripe="true" style="width: 100%">
                <el-table-column prop="complaint_type" label="反馈产品"> </el-table-column>
                <el-table-column prop="works_name" label="作品名称"> </el-table-column>
                <el-table-column prop="feedback_date" label="反馈时间"> </el-table-column>
                <el-table-column prop="feedback_number" label="反馈单号"> </el-table-column>
                <el-table-column prop="deal_status" label="反馈状态">
                    <template slot-scope="{ row }">
                        <div :class="row.status | changeColor">
                            {{ row.deal_status }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" min-width="100">
                    <template slot-scope="scope">
                        <p class="detail" @click="godetail(scope.row)">查看详情</p>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-if="tabledata.length"
                background
                layout="prev, pager, next"
                :page-size="showRows"
                :current-page="cpage"
                :total="total"
                @current-change="changeC"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import PTable from '@/components/publicComponents/PTable.vue';
import { repeat } from '@/api/getData.js';
import { Loading } from 'element-ui';
import product from '@/assets/data/product.js';
export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        PTable,
    },
    data() {
        return {
            notIcon: require('@/assets/img/emptys.png'),
            searchicon: require('@/assets/img/searchicon.svg'),
            hoversearch: require('@/assets/img/hoversearch.svg'),
            tabledata: [], // 反馈记录--总数据
            showRows: 10, // 反馈记录--每页显示的条数
            cpage: 1, // 反馈记录--当前页
            total: 0, // 反馈记录-总条数
            search: '',
            off: true,
            curIndex: 0,
            tableName: [
                {
                    name: '反馈产品',
                    key: 'complaint_type',
                },
                {
                    name: '作品名称',
                    key: 'works_name',
                },
                {
                    name: '反馈时间',
                    key: 'feedback_date',
                },
                {
                    name: '反馈单号',
                    key: 'feedback_number',
                },
                {
                    name: '反馈状态',
                    key: 'deal_status',
                },
            ],
        };
    },
    computed: {
        getclass() {
            return this.off ? 'el-input__icon el-icon-search' : 'el-input__icon el-icon-search on';
        },
    },
    methods: {
        changeC(e) {
            // 切换分页
            this.cpage = e;
            this.initList({
                keyWord: this.search,
                page: e,
                size: this.showRows,
                status: this.curIndex,
            });
        },
        gCype(type) {
            let obj = {};
            product.forEach((item) => {
                obj[item.value] = item.label;
            });
            for (var key in obj) {
                if (type === key) {
                    return obj[key];
                }
            }
        },
        TabCont(index) {
            this.curIndex = index;
            sessionStorage.setItem('TabIndex', this.curIndex);
            this.cpage = 1;
            this.initList({
                keyWord: this.search,
                size: this.showRows,
                page: 1,
                status: index,
            });
        },
        rTime(date) {
            var json_date = new Date(date).toJSON();
            let str = new Date(+new Date(json_date) + 8 * 3600 * 1000).toISOString();
            return str
                .replace(/T/g, ' ')
                .replace(/\.[\d]{3}Z/, '')
                .split(' ')[0];
        },
        godetail(e) {
            // 查看详情
            console.log(e);
            this.$router.push({
                path: 'redetail?id=' + e.id,
            });
        },
        focus() {
            this.off = !this.off;
        },
        initList(data, off) {
            // 获取反馈记录列表
            let loadingInstance = {};
            if (off) {
                loadingInstance = Loading.service();
            }
            repeat(data).then((res) => {
                if (off) {
                    loadingInstance.close();
                }
                const { records = [], total } = res?.data || {};
                records.forEach((item) => {
                    if (item.deal_status === 1) {
                        item.deal_status = '处理中';
                        item.status = 1;
                    } else if (item.deal_status === 2) {
                        item.deal_status = '已受理';
                        item.status = 2;
                    } else {
                        item.deal_status = '已受理';
                        item.status = 3;
                    }
                    item.feedback_date = this.rTime(item.feedback_date);
                });
                records.forEach((item) => {
                    item.complaint_type = this.gCype(item.complaint_type + '');
                });
                this.tabledata = records;
                this.total = total;
            });
        },
        searchInput() {
            const params = {
                keyWord: this.search,
                page: this.cpage,
                size: this.showRows,
                status: this.curIndex,
            };
            this.initList(params, true);
        },
    },
    mounted() {
        let curIndex = sessionStorage.getItem('TabIndex');
        if (curIndex) {
            this.curIndex = curIndex - 0;
        }
        this.initList({
            keyWord: '',
            size: this.showRows,
            status: this.curIndex,
        });
    },
    filters: {
        changeColor(newValue) {
            switch (newValue) {
                case 1:
                    return 'pending';
                case 2:
                    return 'success';
                case 3:
                    return 'success1';
            }
        },
    },
    watch: {
        search(newValue, oldValue) {
            if (newValue.length === 0) {
                this.initList({
                    keyWord: this.search,
                    page: this.cpage,
                    size: this.showRows,
                    status: this.curIndex,
                });
            }
        },
    },
};
</script>
<style scoped lang='less'>
::v-deep .el-pagination .btn-prev:disabled {
    background: #fafbfb !important;
}
::v-deep .el-table .cell {
    padding-left: 24px !important;
    text-align: left;
}
::v-deep .el-pagination .btn-next:disabled {
    background: #fafbfb !important;
}
::v-deep el-table__row > td {
    border: none;
}
::v-deep .el-table::before {
    height: 0px;
}
::v-deep .el-table {
    height: 520px !important;
    overflow-y: scroll;
}
::v-deep .el-table tbody tr:hover > td {
    background-color: #e6edff !important;
}
[class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
::v-deep .el-pagination [class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
.el-table .success {
    color: #41cca2;
}
.el-table .success1 {
    color: #e63232;
}
.el-table .pending {
    color: #fa8b4b;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #f5f7fc;
}
::v-deep .el-table--striped .el-table__header-wrapper th {
    background: #f5f7fc;
    color: #27272d;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: rgba(45, 99, 224, 0.2);
    border: 1px solid #2d63e0;
    border-radius: 5px;
    color: #2d63e0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2d63e0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
::v-deep .el-pagination.is-background .btn-next {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
::v-deep .el-pagination.is-background .btn-prev {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
::v-deep .el-pagination.is-background .el-pager li {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
.empty {
    margin-top: 70px;
    img {
        display: block;
        width: 169px;
        height: 169px;
        margin: 0 auto;
    }
    p {
        text-align: center;
        font-size: 16px;
        color: #767981;
    }
}
.el-input__icon.on {
    color: #2d63e0;
}
.record {
    width: 1200px;
    background: #fff;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 0 120px 0;
    .content {
        width: 1010px;
        min-height: 100vh;
        margin: 0 auto;
        ::v-deep .el-pagination {
            margin: 28px 0;
        }
    }
    .top {
        width: 1010px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        ::v-deep .search {
            line-height: 110px;
            box-sizing: border-box;
            position: relative;
            .el-icon-search:before {
                content: '';
            }
            i {
                position: absolute;
                right: 10px;
                top: 47px;
                display: inline-block;
                width: 19px;
                height: 19px;
                background: url(../assets/img/searchicon.svg) no-repeat;
                &:hover {
                    background: url(../assets/img/hoversearch.svg) no-repeat;
                }
                &.on {
                    background: url(../assets/img/hoversearch.svg) no-repeat;
                }
            }
        }
        ul {
            display: flex;
            height: 110px;
            align-items: center;
            li {
                position: relative;
                height: 26px;
                margin-right: 20px;
                font-size: 20px;
                color: #a6a8ab;
                &:hover {
                    color: #1e2024;
                }
                i {
                    position: absolute;
                    top: 0;
                    right: -10px;
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                }
                &:hover {
                    cursor: pointer;
                }
                &.on {
                    color: #1e2024;
                    i {
                        background: url(../assets/img/title2.png) no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}
.el-input__icon:hover {
    cursor: pointer;
    color: #2d63e0;
}
</style>