<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
    <div class="ptable">
        <p v-show="show" class="title">侵权链接</p>
        <el-table :data="tabledata" :stripe="true" :row-class-name="tableRowClass" max-height="430" style="width: 100%">
            <el-table-column
                v-for="(item, index) in tablename"
                :key="index"
                :prop="item.key"
                :label="item.name"
                :width="wname(item)"
            >
                <template slot-scope="{ row }">
                    <div v-if="item.key === 'url'">
                        <a target="_blank" :href="row.url">
                            <p class="ellipsis">{{ row.url }}</p>
                        </a>
                    </div>
                    <div v-else>
                        {{ row[item.key] }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="!shows" label="操作" align="center" min-width="100">
                <template slot-scope="scope">
                    <p class="detail" @click="godetail(scope.row)">查看详情</p>
                </template>
            </el-table-column>
        </el-table>
        <div v-show="show" class="box"></div>
    </div>
</template>

<script>
export default {
    props: {
        tabledata: {
            type: Array,
            required: true,
        },
        tablename: {
            type: Array,
            required: true,
        },
        show: {
            type: Boolean,
            required: false,
            default: true,
        },
        shows: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isshow: false,
        };
    },
    methods: {
        tableRowClass(e) {
            // 行的className回掉
            if (e.row.deal_status === '已受理') {
                return 'success';
            } else if (e.row.deal_status === '处理中') {
                return 'pending';
            } else if (e.row.result === '已处理') {
                return 'res';
            } else if (e.row.result === '不违规') {
                return 'rej';
            }
        },
        wname(a) {
            if (a.key === 'url_name') {
                return 180;
            } else if (a.key === 'id') {
                return 120;
            } else {
                return '';
            }
        },
        godetail(e) {
            // 查看详情
            this.$router.push({
                path: 'redetail?id=' + e.id,
            });
        },
    },
    filters: {
        changeColor(newValue) {
            switch (newValue) {
                case '不违规':
                    return 'rej';
                case '已处理':
                    return 'res';
                case '已过期':
                    return 'expired';
                default:
                    return 'rejected';
            }
        },
    },
    mounted() {
        this.isshow = true;
    },
};
</script>

<style scoped lang="less">
.ptable {
    width: 100%;
    // position: relative;
    // .box{
    //     width: 100%;
    //     height: 100px;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     // background-image: linear-gradient(transparent,#fff);
    // }
    .title {
        font-size: 14px;
        color: #27272d;
        font-weight: bold;
        margin-bottom: 12px;
    }
    ::v-deep el-table__row > td {
        border: none;
    }
    ::v-deep .el-table::before {
        height: 0px;
    }
    /deep/ .el-table {
        .ellipsis {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            white-space: normal;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
        }
    }
}
</style>
<style>
.el-table .success .el-table_1_column_5 {
    color: #34c934;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #f5f7fc;
}
.el-table--striped .el-table__header-wrapper th {
    background: #f5f7fc;
    color: #27272d;
}
.el-table .pending .el-table_1_column_5 {
    color: #fa8b4b;
}
.el-table .check .el-table_1_column_6 {
    color: #2d63e0;
}
.el-table .detail {
    color: #2d63e0;
}
.el-table .res .el-table_1_column_3 {
    color: #41cca2;
}
.el-table .rej .el-table_1_column_3 {
    color: #e63232;
}
.el-table .detail:hover {
    cursor: pointer;
}
</style>
